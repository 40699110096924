import React, { useEffect } from "react";
import { Box, Grid, styled, Typography, useTheme } from "@mui/material";
import { ReactComponent as ArticleReview } from "../../../assets/images/ArticleReview.svg";
import {
  authGuidelinesArticlePoints,
  luminaryMembershipAdvantagePoints,
  luminaryMembershipEligibleCriteriaPoints,
  reviewerGuidelinesPoints,
} from "../../../helpers/constant";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchJournalById } from "../../../redux/slices/webJournal";

const StepsOl = styled("ol")(({ theme }) => ({
  counterReset: "peerSteps",
  listStyle: "none",
  margin: "0",
  paddingLeft: "23px",

  "& > li": {
    marginBottom: "20px",
    display: "table",
    counterIncrement: "peerSteps",
    listStyleType: "none",

    "&:before": {
      display: "table-cell",
      content: '"Step " counters(peerSteps, ".") ": "',
      paddingRight: "0.6em",
      width: "65px",
    },
  },
}));

const ReviewerGuidelines = () => {
  const dispatch = useDispatch();
  const { journalId } = useParams();

  const { journalDetails, journalLoading } = useSelector(
    (state) => state.webJournalReducer
  );

  useEffect(() => {
    if (journalId) {
      dispatch(fetchJournalById({ journalCustomId: journalId }));
    }
  }, [journalId, dispatch]);

  return (
    <Box
      sx={{
        padding: "20px 40px",
        height: "100%",
        wordBreak: "break-word",
        // background: theme.palette.primary.litSurface,
      }}
    >
      <Box sx={{ marginBottom: "30px" }}>
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            fontWeight: 600,
            marginBottom: "20px",
          }}
        >
          REVIEWER GUIDELINES
        </Typography>
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <Grid item sx={12} sm={12} md={7} lg={7}>
            <Typography
              //   variant="h5"
              gutterBottom
              sx={{
                fontWeight: 600,
              }}
            >
              <strong>Peer Review Guidelines</strong>
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                marginBottom: "20px",
              }}
            >
              All research articles and most other article types published in '
              {journalDetails?.name}' undergo peer review, typically involving
              at least two independent, expert reviewers.
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                marginBottom: "20px",
              }}
            >
              Submissions undergo a quality check for completeness before being
              sent to an Editor for evaluation of their suitability for peer
              review. The editorial board maintains complete control over the
              journal's scientific quality control. If the Editor has a conflict
              of interest, such as being listed as an author or having any other
              competing interest with the manuscript, another Editorial Board
              member will be assigned to oversee the peer review process.
              Editors consider peer-reviewed reports when making their decisions
              but are not obligated to follow the reviewers' opinions or
              recommendations. Authors will receive the peer review reports
              along with the editorial decision on their manuscript.
            </Typography>
          </Grid>
          <Grid item sx={12} sm={12} md={5} lg={5}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ArticleReview style={{ maxWidth: "335px" }} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ marginBottom: "30px", textAlign: "justify" }}>
        <Typography
          //   variant="h5"
          gutterBottom
          sx={{
            fontWeight: 600,
          }}
        >
          <strong>Type of Peer Review</strong>
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            marginBottom: "20px",
          }}
        >
          '{journalDetails?.name}' uses a double-blind peer review process. The
          identities of both authors and reviewers are concealed from each other
          to ensure an independent and unbiased review process.
        </Typography>
      </Box>
      <Box sx={{ marginBottom: "30px", textAlign: "justify" }}>
        <Typography
          //   variant="h5"
          gutterBottom
          sx={{
            fontWeight: 600,
          }}
        >
          <strong>Editorial Structure and Role</strong>
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            marginBottom: "20px",
          }}
        >
          Editor-in-Chief: The highest-ranking member of the editorial team,
          responsible for managing the team of editors, determining the
          publication's direction, deciding what to publish, and overseeing the
          publication's operations and policies.
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            marginBottom: "20px",
          }}
        >
          Associate Editors: These editors play a key role in peer-reviewed
          publishing, supporting the journal Editor as subject experts. They
          oversee assigned manuscripts, guiding them through review and
          revision, assessing quality, obtaining peer reviews, requesting
          revisions, and making recommendations to the journal Editor about
          acceptance or rejection.
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            marginBottom: "20px",
          }}
        >
          Guest Editors: Appointed for special content publications, these
          editors are responsible for ensuring quality. The Editor-in-Chief
          decides on proposals based on reviews from the editorial board.
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            marginBottom: "20px",
          }}
        >
          Reviewers: Provide feedback on the paper, suggest improvements, and
          recommend to the editor whether to accept, reject, or request changes.
          The ultimate decision rests with the editor, but reviewers
          significantly influence the outcome.
        </Typography>
      </Box>
      <Box sx={{ marginBottom: "30px", textAlign: "justify" }}>
        <Typography
          //   variant="h5"
          gutterBottom
          sx={{
            fontWeight: 600,
          }}
        >
          <strong>Peer Review and Editorial Process</strong>
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            marginBottom: "20px",
          }}
        >
          The following describes the first round of the review process for all
          manuscripts submitted to '{journalDetails?.name}':
        </Typography>
        <StepsOl>
          {reviewerGuidelinesPoints.map((point) => (
            <li>
              <Typography variant="body1" gutterBottom>
                {point.text}
              </Typography>
              <ul>
                {point?.nestedPoints?.length > 0 &&
                  point?.nestedPoints?.map((nestedPoint) => (
                    <li>{nestedPoint.text}</li>
                  ))}
              </ul>
            </li>
          ))}
        </StepsOl>
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            marginBottom: "20px",
          }}
        >
          Timeline guidance may vary for each submission.
        </Typography>
      </Box>
      <Box sx={{ marginBottom: "30px", textAlign: "justify" }}>
        <Typography
          //   variant="h5"
          gutterBottom
          sx={{
            fontWeight: 600,
          }}
        >
          <strong>Appeal Process</strong>
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            marginBottom: "20px",
          }}
        >
          Appeals are considered case by case and must be submitted in writing
          to the journal's editorial office (
          <a href={`mailto:${process.env.REACT_APP_IFRD_EMAIL}`}>
            {process.env.REACT_APP_IFRD_EMAIL}
          </a>
          ). Appeals on the basis of novelty or scope are unlikely to be
          granted.
        </Typography>
      </Box>
    </Box>
  );
};

export default ReviewerGuidelines;
