import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LayoutRoute from "./LayoutRoute";
import AuthRoute from "./AuthRoute";
import {
  AboutMembership,
  Home,
  JournalList,
  StudentMembership,
  PrivilegeMembership,
  ViewJournal,
  LuminaryMembership,
  DelegateMembership,
  SubmitArticle,
  ArchiveList,
  ArticleList,
  ArticleView,
  EditorList,
  TrackSubmitArticle,
  AboutUs,
  AuthorGuidelines,
  ReviewerGuidelines,
} from "../pages/website";
import {
  AdminHome,
  AdminSubject,
  AdminJournalList,
  AdminJournalAddEdit,
  AdminLogin,
  AdminArticleList,
  AdminAddEditArticle,
  AdminArchiveList,
  AdminArticleSubmissionList,
  AdminJournalEditorList,
  AdminAddEditEditor,
  AdminAddEditArticleSubmission,
} from "../pages/adminDashboard";
import Test from "../pages/Test";
import Error from "../pages/Error";

const MainRouter = () => {
  return (
    <Router>
      <Routes>
        <Route element={<LayoutRoute />}>
          <Route path="/" element={<Home />} />
          <Route path="/journal" element={<JournalList />} />
          <Route path="/journal/:journalId" element={<ViewJournal />} />
          <Route
            path="/journal/:journalId/author-guidelines"
            element={<AuthorGuidelines />}
          />
          <Route
            path="/journal/:journalId/reviewer-guidelines"
            element={<ReviewerGuidelines />}
          />
          <Route path="/membership" element={<AboutMembership />} />
          <Route path="/membership/student" element={<StudentMembership />} />
          <Route
            path="/membership/privilege"
            element={<PrivilegeMembership />}
          />
          <Route path="/membership/luminary" element={<LuminaryMembership />} />
          <Route path="/membership/delegate" element={<DelegateMembership />} />
          <Route path="/article/submit" element={<SubmitArticle />} />
          <Route path="/article/track" element={<TrackSubmitArticle />} />
          <Route path="/archive/:journalId" element={<ArchiveList />} />
          <Route path="/issue/:issueId" element={<ArticleList />} />
          <Route path="/article/:articleId" element={<ArticleView />} />
          <Route path="/editor/:journalId" element={<EditorList />} />
          <Route path="/about-us" element={<AboutUs />} />
        </Route>

        <Route element={<AuthRoute />}>
          <Route path="/admin/home" element={<AdminHome />} />
          <Route path="/admin/subject" element={<AdminSubject />} />
          <Route path="/admin/journal" element={<AdminJournalList />} />
          <Route
            path="/admin/journal/:journalAddType"
            element={<AdminJournalAddEdit />}
          />
          <Route path="/admin/archive" element={<AdminArchiveList />} />
          <Route path="/admin/article" element={<AdminArticleList />} />
          <Route
            path="/admin/article/:articleAddType"
            element={<AdminAddEditArticle />}
          />
          <Route
            path="/admin/article-submission"
            element={<AdminArticleSubmissionList />}
          />
          <Route
            path="/admin/article-submission/:articleSubmissionAddType"
            element={<AdminAddEditArticleSubmission />}
          />
          <Route path="/admin/editor" element={<AdminJournalEditorList />} />
          <Route
            path="/admin/editor/:editorAddType"
            element={<AdminAddEditEditor />}
          />
        </Route>
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/test" element={<Test />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </Router>
  );
};

export default MainRouter;
