import React from "react";
import { useTheme } from "@emotion/react";
import { Box, Button, Grid, Typography, styled } from "@mui/material";
import { ReactComponent as Building } from "../../assets/images/Building.svg";

const StyledBox = styled(Grid)(({ theme }) => ({
  width: "100%",
  height: "100%",
  // background: theme.palette.primary.main,
  borderRadius: theme.borderRadius[10],
  padding: "25px",
  boxShadow: theme.boxShadow.shadowThree,
  marginBottom: "10px",
  textAlign: "center",
}));

const AboutUs = () => {
  return (
    <Box
      sx={{
        padding: "20px 40px",
        height: "100%",
        textAlign: "justify",
        // background: theme.palette.primary.litSurface,
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          fontWeight: 600,
          marginBottom: "20px",
        }}
      >
        About Organization
      </Typography>
      <Grid container justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography
            variant="body1"
            gutterBottom
            sx={{
              marginBottom: "20px",
            }}
          >
            IFRD INTERNATIONAL LLP emerged as a pioneering force in the realm of
            research and development, driven by a profound dedication to
            advancing the frontiers of knowledge. From our headquarters nestled
            in the vibrant heart of India, we embark on a journey infused with a
            spirit of innovation, collaboration, and global engagement. Our
            vision extends far beyond geographical boundaries, as we aspire to
            catalyse a collective pursuit of excellence that transcends borders,
            cultures, and disciplines.
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            sx={{
              marginBottom: "20px",
            }}
          >
            At the core of our mission lies a steadfast commitment to empowering
            individuals and institutions to harness their intellectual prowess
            for the betterment of humanity. We envision a world where
            researchers, academics, and professionals converge to exchange
            ideas, ignite new pathways of inquiry, and shape the future through
            their collective endeavours. Through our unwavering dedication, we
            seek to install a culture of inquiry, curiosity, and discovery that
            propels society forward and unlocks the boundless potential of human
            ingenuity.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Building style={{ maxWidth: "400px" }} />
          </Box>
        </Grid>
      </Grid>
      <Typography
        variant="body1"
        gutterBottom
        sx={{
          marginBottom: "20px",
        }}
      >
        Within the dynamic landscape of research and development, we serve as
        catalysts for change, facilitators of collaboration, and champions of
        knowledge dissemination. With a keen eye towards the evolving needs of
        the global community, we strive to bridge gaps, spark dialogue, and
        foster meaningful connections that transcend traditional boundaries. Our
        commitment to excellence permeates every facet of our operations,
        guiding us as we navigate the ever-changing currents of innovation and
        discovery.
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        sx={{
          marginBottom: "40px",
        }}
      >
        As stewards of progress, we recognize the transformative power of
        research and the profound impact it has on shaping our collective
        future. Through our diverse initiatives, collaborative partnerships, and
        unwavering dedication, we endeavour to pave the way for a world where
        the boundaries of possibility are continually pushed, and the fruits of
        knowledge are accessible to all who seek them. Together, let us embark
        on a journey of exploration, enlightenment, and endless possibilities as
        we chart a course towards a brighter tomorrow.
      </Typography>
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          fontWeight: 600,
          marginBottom: "20px",
        }}
      >
        Our Publisher
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        sx={{
          marginBottom: "20px",
        }}
      >
        At the heart of our organization beats the pulse of the INTERNATIONAL
        FEDERATION OF RESEARCH AND DISCOVERY (IFR Discovery), an esteemed
        publisher rooted in the rich heritage of Jordan. With an unwavering
        commitment to excellence in scientific publishing, IFR Discovery stands
        as a beacon of scholarly integrity and innovation, propelling the
        boundaries of knowledge dissemination to new heights.
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        sx={{
          marginBottom: "20px",
        }}
      >
        Drawing upon a legacy of academic rigor and visionary leadership, IFR
        Discovery curates a diverse portfolio of scholarly journals, each
        meticulously crafted to represent the pinnacle of research excellence
        across various disciplines. Currently boasting a collection of 9
        peer-reviewed journals, our publications span the spectrum of human
        inquiry, offering researchers a dynamic platform to showcase their
        findings, engage in rigorous scholarly discourse, and catalyze
        meaningful contributions to the global body of knowledge.
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        sx={{
          marginBottom: "20px",
        }}
      >
        Beyond serving as mere repositories of information, our journals serve
        as vibrant hubs of intellectual exchange, fostering a culture of
        collaboration, innovation, and discovery. Guided by a commitment to
        inclusivity and diversity, we actively seek to amplify underrepresented
        voices, champion emerging fields of study, and cultivate
        interdisciplinary dialogue that transcends traditional boundaries.
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        sx={{
          marginBottom: "20px",
        }}
      >
        Through the pages of our publications, researchers embark on a
        transformative journey of exploration, enlightenment, and discovery,
        propelled by the collective pursuit of truth and understanding. With a
        global reach that spans continents and cultures, our journals serve as
        catalysts for positive change, driving advancements that reverberate
        across the scholarly landscape and beyond.
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        sx={{
          marginBottom: "40px",
        }}
      >
        As stewards of knowledge, we recognize the profound impact of our work
        on shaping the future of academia and society at large. With an
        unwavering dedication to excellence and a relentless pursuit of
        innovation, IFR Discovery remains steadfast in its mission to empower
        researchers, inspire minds, and catalyze transformative change on a
        global scale. Join us as we embark on a journey of discovery,
        enlightenment, and boundless possibility, guided by the guiding
        principle that knowledge has the power to change the world.
      </Typography>
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          fontWeight: 600,
          marginBottom: "20px",
        }}
      >
        Aims and Objectives
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        sx={{
          marginBottom: "20px",
        }}
      >
        In addition to our core mission, IFRD INTERNATIONAL LLP is committed to
        achieving the following aims and objectives in the field of research and
        development:
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        sx={{
          marginBottom: "20px",
        }}
      >
        <strong>Promoting Diversity and Inclusivity:</strong> We aim to foster
        diversity and inclusivity in research by providing a platform for
        researchers from diverse backgrounds, disciplines, and regions to share
        their work and perspectives. Through inclusive practices, we seek to
        amplify underrepresented voices and promote equitable access to research
        opportunities.
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        sx={{
          marginBottom: "20px",
        }}
      >
        <strong>Encouraging Interdisciplinary Collaboration:</strong> We
        recognize the value of interdisciplinary collaboration in driving
        innovation and addressing complex challenges. As such, we aim to
        facilitate interdisciplinary research initiatives, encourage
        cross-disciplinary dialogue, and support collaborations that transcend
        traditional boundaries to generate impactful solutions.
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        sx={{
          marginBottom: "20px",
        }}
      >
        <strong>Fostering Open Science Practices:</strong> We are committed to
        promoting open science practices that enhance transparency,
        reproducibility, and accessibility in research. By advocating for open
        access publishing, data sharing, and collaborative approaches, we aim to
        democratize knowledge and accelerate scientific progress for the benefit
        of society.
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        sx={{
          marginBottom: "40px",
        }}
      >
        <strong>Supporting Early Career Researchers:</strong> We recognize the
        importance of nurturing the next generation of researchers and
        innovators. Through mentorship programs, networking opportunities, and
        tailored support services, we aim to empower early career researchers to
        succeed in their academic and professional endeavors, thereby
        cultivating a vibrant research community.
      </Typography>
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          fontWeight: 600,
          marginBottom: "20px",
        }}
      >
        Our Services
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        sx={{
          marginBottom: "20px",
        }}
      >
        IFRD INTERNATIONAL LLP offers a comprehensive range of services to
        support researchers, institutions, and organizations in their research
        and publishing endeavors. Our services include:
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        sx={{
          marginBottom: "20px",
        }}
      >
        <strong>Publication Support:</strong> Through our publisher, IFR
        Discovery, we offer end-to-end publication support services, including
        manuscript submission, peer review management, and publication
        dissemination, to ensure that research findings reach a global audience.
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        sx={{
          marginBottom: "20px",
        }}
      >
        <strong>Research Collaboration:</strong> We facilitate research
        collaboration and partnership opportunities, connecting researchers,
        institutions, and organizations to promote interdisciplinary
        collaboration and maximize the impact of research efforts.
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        sx={{
          marginBottom: "20px",
        }}
      >
        <strong>Capacity Building:</strong> We provide training, workshops, and
        resources to enhance research skills, promote academic integrity, and
        empower individuals and organizations to excel in their research
        pursuits.
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        sx={{
          marginBottom: "40px",
        }}
      >
        <strong>Consulting and Advisory:</strong> Our team of experts offers
        consulting and advisory services to assist organizations in developing
        research strategies, implementing best practices, and navigating the
        evolving landscape of scholarly publishing.
      </Typography>
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          fontWeight: 600,
          marginBottom: "20px",
        }}
      >
        Get In Touch
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        sx={{
          marginBottom: "20px",
        }}
      >
        Whether you're a researcher seeking to publish your work, an institution
        looking to collaborate on research projects, or an organization
        interested in our services, we invite you to reach out to us. Together,
        let's embark on a journey of discovery, innovation, and impactful
        research.
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        sx={{
          marginBottom: "20px",
        }}
      >
        Thank you for choosing IFRD INTERNATIONAL LLP as your partner in
        advancing research and development.
      </Typography>
    </Box>
  );
};

export default AboutUs;
