export const adminSideBarMenu = [
  {
    name: "Home",
    navigate: "/admin/home",
    icon: <i className="ri-home-2-fill ri-lg"></i>,
  },
  {
    name: "Subject",
    navigate: "/admin/subject",
    icon: <i className="ri-book-fill ri-lg"></i>,
  },
  {
    name: "Journal",
    navigate: "/admin/journal",
    icon: <i className="ri-booklet-fill ri-lg"></i>,
  },
  {
    name: "Archives",
    navigate: "/admin/archive",
    icon: <i className="ri-archive-fill ri-lg"></i>,
  },
  {
    name: "Editors",
    navigate: "/admin/editor",
    icon: <i className="ri-user-2-fill ri-lg"></i>,
  },
  {
    name: "Article Submissions",
    navigate: "/admin/article-submission",
    icon: <i className="ri-file-list-3-fill ri-lg"></i>,
  },
];

export const subjectListTableColumn = ["Name", "Updated By", "Updated At", ""];

export const journalListTableColumn = [
  "Name",
  "Language",
  "Origin",
  "Updated By",
  "Updated At",
  "",
];

export const issueListTableColumn = [
  "Name",
  "Archive",
  "Journal",
  "Updated By",
  "Updated At",
  "",
];

export const articleListTableColumn = [
  "Name",
  "Issue",
  "Archive",
  "Page No.",
  "Status",
  "Updated By",
  "Updated At",
  "",
];

export const articleSubmissionListTableColumn = [
  "Tracking ID",
  "Manuscript Title",
  "Author name",
  "Contacts",
  "Journal",
  "Article Type",
  // "Updated By",
  "Submitted At",
  "",
];

export const editorListTableColumn = [
  "Name",
  "Journal",
  "Designation",
  "Contacts",
  "updated by",
  "updated at",
  "",
];

/**
 * Website constants
 */
export const navbarMenu = (
  {
    journalId = "",
    journalCustomId = "",
    issueId = "",
    articleId = "",
    pathname,
  },
  navigate
) => {
  let includesJournalPath = [
    "/journal/:journalId/author-guidelines",
    "/journal/:journalId/reviewer-guidelines",
  ].includes(pathname);

  if (journalId || issueId || articleId || includesJournalPath) {
    return [
      {
        name: "IFRD Home",
        route: "/",
        type: "button",
      },
      {
        name: "About Journal",
        route: `/journal/${journalId || journalCustomId}`,
        type: "button",
      },
      {
        name: "Editorial Board",
        route: `/editor/${journalId || journalCustomId}`,
        type: "button",
      },
      {
        name: "Instructions",
        type: "menu",
        menuOptions: [
          // {
          //   name: "Advisory Board",
          //   onSelect: () => navigate("/membership"),
          // },
          {
            name: "Author Guidelines",
            onSelect: () =>
              navigate(
                `/journal/${journalId || journalCustomId}/author-guidelines`
              ),
          },
          // {
          //   name: "Editorial Guidelines",
          //   onSelect: () => navigate("/journal/author-guidelines"),
          // },
          {
            name: "Reviewer's Guidelines",
            onSelect: () =>
              navigate(
                `/journal/${journalId || journalCustomId}/reviewer-guidelines`
              ),
          },
        ],
      },
      {
        name: "Ethics & Policies",
        route: "",
        type: "button",
      },
      {
        name: "Archive",
        route: `/archive/${journalId || journalCustomId}`,
        type: "button",
      },
      {
        name: "APC",
        route: "",
        type: "button",
      },
      {
        name: "Reproducing Form",
        route: "",
        type: "button",
      },
    ];
  }

  return [
    {
      name: "Home",
      route: "/",
      type: "button",
    },
    {
      name: "Journals",
      route: "/journal",
      type: "button",
    },
    {
      name: "Membership",
      // route: "/",
      type: "menu",
      menuOptions: [
        {
          name: "About Membership",
          onSelect: () => navigate("/membership"),
        },
        {
          name: "Apply Membership",
          onSelect: () => navigate("/"),
        },
        {
          name: "Student Insider Membership",
          onSelect: () => navigate("/membership/student"),
        },
        {
          name: "Privileged Membership",
          onSelect: () => navigate("/membership/privilege"),
        },
        {
          name: "Luminary Membership",
          onSelect: () => navigate("/membership/luminary"),
        },
        {
          name: "Delegate Membership",
          onSelect: () => navigate("/membership/delegate"),
        },
      ],
    },
    {
      name: "Conference",
      route: "#",
      type: "button",
    },
    {
      name: "Payments",
      route: "#",
      type: "button",
    },
    {
      name: "About us",
      route: "/about-us",
      type: "button",
    },
  ];
};

export const publishWithIFRDiscoveryCards = [
  {
    title: "Open Access Journal",
    buttonText: "Submit Article",
    inputPlaceholder: "Choose Journal",
    isDropDown: false,
    tag: "journal",
  },
  {
    title: "Track Manuscript",
    buttonText: "Get Details",
    inputPlaceholder: "Type Manuscript No",
    isDropDown: false,
    tag: "track",
  },
  {
    title: "Browse Subjects",
    buttonText: "Submit",
    inputPlaceholder: "choose Subjects",
    isDropDown: false,
    tag: "browse",
  },
];

export const publishWithIFRDiscoveryDisplayCards = [
  {
    title: "Membership",
    imageTag: "membership",
    description: "Be a part of our family",
  },
  {
    title: "Upcoming Conference",
    imageTag: "conference",
    description: "Empowering scholars for extra miles",
  },
  {
    title: "Journal & Publication",
    imageTag: "article",
    description: "Discover Globally Peer-Reviewed Journals",
  },
];

export const supportHubCards = [
  {
    title: "Journal Assistance Center",
    imageTag: "creative",
    description:
      "Simplify Your Publication Workflow and Enhance Your Research Influence",
  },
  {
    title: "Editorial Board",
    imageTag: "article",
    description: "",
    isButton: true,
    buttonText: "Join as Editor",
  },
  {
    title: "Publication Assistance Center",
    imageTag: "assistance",
    description:
      "Taking Your Research to New Pinnacles with Professional Publishing Assistance",
  },
  {
    title: "Reviewer Board",
    imageTag: "review",
    description: "",
    isButton: true,
    buttonText: "Join as Reviewer",
  },
];

export const socialMediaArray = [
  {
    imageTag: "Facebook",
  },
  {
    imageTag: "Instagram",
  },
  {
    imageTag: "Twitter",
  },
  {
    imageTag: "Linkedin",
  },
  {
    imageTag: "Youtube",
  },
];

export const footerQuickLinksArray = [
  {
    name: "Terms & Conditions",
  },
  {
    name: "Privacy Policy",
  },
  {
    name: "Refund Policy",
  },
  {
    name: "Hard Copy",
  },
  {
    name: "Payments",
  },
];

export const footerOthersArray = [
  {
    name: "About Us",
  },
  {
    name: "Contact Us",
  },
  {
    name: "Join as Editor",
  },
  {
    name: "Join as Reviewer",
  },
  {
    name: "Careers",
  },
  {
    name: "FAQ",
  },
];

export const aboutMembershipPoints = [
  {
    text: "Priority access to submit your research articles across our diverse range of journals.",
  },
  {
    text: "Expedited peer review process to fast-track the publication of your work.",
  },
  {
    text: "Access to specialized resources and tools to support your research endeavors.",
  },
  {
    text: "Networking opportunities and collaboration initiatives to connect with peers in your field.",
  },
];

export const aboutMembershipSectionLinks = [
  {
    name: "Student Insider Membership",
    link: "/membership/student",
  },
  {
    name: "Privileged Membership",
    link: "/membership/privilege",
  },
  {
    name: "Luminary Membership",
    link: "/membership/luminary",
  },
  {
    name: "Delegate Membership",
    link: "/membership/delegate",
  },
];

export const studentMembershipAdvantagePoints = [
  {
    text: "Unlock the Gateway to your professional future with our prestigious ‘Student Insider E-Membership (SMIFRD)’ Certificate.",
  },
  {
    text: "Membership offers the benefit of publishing your first 2 research articles free of cost.",
  },
  {
    text: "Membership grants you a 50% discount on publication fees for all our journals till the membership validity.",
  },
  {
    text: "Members have the privilege of participating in all National and International Seminars/Conferences with a 50% discount on registration fees.",
  },
  {
    text: "Membership Validity up to 3 years.",
  },
  {
    text: "Members will be promoted to Privileged Membership (PMIFRD) after the expiry of Student Insider Membership (SMIFRD). Check (T&C Apply)",
  },
];

export const privilegeMembershipAdvantagePoints = [
  {
    text: "Members will be presented with an exclusive IFRD specially designed Silver Medal of membership, accompanied by a framed certificate, and delivered to their residential address.",
  },
  {
    text: "Gain access to a prestigious E-Membership Certificate, a valuable tool for advancing both academic and professional endeavours.",
  },
  {
    text: "Experience the advantage of expedited article publication through our Fast-Track Mode, ensuring publication within just 15 business days.",
  },
  {
    text: "Membership offers the privilege of publishing your first four research articles free of charge.",
  },
  {
    text: "Members will enjoy a substantial 50% discount on publication fees across all our journals.",
  },
  {
    text: "Benefit from a 50% waiver on registration fees for all National and International Seminars/Conferences scheduled for upcoming events.",
  },
  {
    text: "Participate in Academic and Research Award applications without any registration fees, an opportunity exclusively available to members",
  },
  {
    text: "Enjoy the enduring benefits of Lifetime Membership Validity, ensuring a lasting connection to our resources and opportunities.",
  },
];

export const privilegeMembershipEligibleCriteriaPoints = [
  {
    text: "Bachelor's Degree with minimum 5 year's experience in Academic, Teaching, Social Services or Research field in any discipline.",
  },
  {
    text: "Master's Degree with minimum 5 year's experience in Academic, Teaching, Social Services or Research field in any discipline.",
  },
  {
    text: "PhD Degree with minimum 5 year's experience in Academic, Teaching, Social Services or Research field in any discipline",
  },
  {
    text: "Applicants whose Student Insider Membership(SMIFRD) has expired and has completed minimum Bachelor's Degree with a history of at least 5 research articles published.",
  },
];

export const luminaryMembershipAdvantagePoints = [
  {
    text: "Luminary members can proudly display the prestigious Luminary designation after their name (Ex: Dr. Sarah Johnson, LMIFRD), signifying their exceptional status.",
  },
  {
    text: "Upon joining, Luminary members will be honoured with an exquisite IFRD specially designed Golden Medal, complemented by a framed membership certificate, delivered to their designated address.",
  },
  {
    text: "Luminary members will receive a coveted E-Membership Certificate, a valuable asset for enhancing their academic and professional journey.",
  },
  {
    text: "Experience the advantage of expedited article publication through our Fast-Track Mode, ensuring publication within just 5 business days.",
  },
  {
    text: "Luminary membership entitles you to publish your initial 5 research articles at Free of Cost.",
  },
  {
    text: "Enjoy a generous 50% discount on publication fees across all our prestigious journals.",
  },
  {
    text: "Luminary members are eligible for a remarkable 50% waiver on registration fees for all National and International Seminars/Conferences slated for upcoming events.",
  },
  {
    text: "Participate in Academic and Research Award applications without any registration fees, exclusively available to Luminary members.",
  },
  {
    text: "Luminary members have the privilege of assuming editorial roles in our esteemed International Journals and are given priority invitations as Highly Regarded Orators at our forthcoming National/International events.",
  },
  {
    text: "Luminary Membership grants you lifetime access to all these exclusive benefits, ensuring your continued academic and professional advancement.",
  },
];

export const luminaryMembershipEligibleCriteriaPoints = [
  {
    text: "Holding Master’s Degree with minimum 10 years experience in Academic, Teaching, Social Services or Research field in any discipline and has published minimum 10 research articles in any national / international journals.",
  },
  {
    text: "Holding PhD Degree with minimum 8 years experience in Academic, Teaching, Social Services or Research field in any discipline and has published minimum 8 research articles in any national / international Journals.",
  },
  {
    text: "Any member who is a part of our Privileged Membership (PMIFRD) and has a history of 25 research articles published in any national / international journals.",
  },
];

export const submitArticleTypeOptions = [
  {
    name: "Original Research Articles",
  },
  {
    name: "Research Article",
  },
  {
    name: "Review Articles",
  },
  {
    name: "Meta-analysis",
  },
  {
    name: "Case Reports",
  },
  {
    name: "Editorial",
  },
  {
    name: "Letter to the Editor",
  },
  {
    name: "Perspective Articles",
  },
  {
    name: "Short Communications",
  },
  {
    name: "Commentary",
  },
  {
    name: "Systematic Review",
  },
  {
    name: "Methodology Articles",
  },
  {
    name: "Protocol Articles",
  },
  {
    name: "Book Reviews",
  },
  {
    name: "Conference Proceedings",
  },
  {
    name: "Thematic Issue Articles",
  },
  {
    name: "Technical Notes",
  },
  {
    name: "Data Papers",
  },
];

export const copyrightFormTC = [
  {
    text: "The copyright transfer includes the exclusive right to reproduce, distribute, display, and publish the Work in print and electronic formats, including but not limited to the Publisher's website, databases, and third-party repositories.",
  },
  {
    text: "The Publisher has the right to edit, revise, and adapt the Work as necessary for publication, including changes to format, style, and content, while maintaining the integrity and original intent of the Work.",
  },
  {
    text: "The transfer of copyright is effective upon acceptance of the Work for publication in the Journal that is only released by IFR Discovery.",
  },
  {
    text: "I warrant that the Work is original, has not been previously published, and is not currently under consideration for publication elsewhere. I further warrant that the Work does not infringe upon any copyright, trademark, or other intellectual property rights of any third party. However, if any conflict arises in the future and found that the paper does not comply with the copyright standards, then the publisher shall have full rights to withdraw or take any action over the paper, to solve the conflict for peaceful settlement.",
  },
  {
    text: " I understand that I retain certain rights to the Work, including the right to use the Work for noncommercial purposes, such as teaching, research, and personal use, provided that proper attribution is given to the Publisher and the original publication in its Journal is acknowledged.",
  },
  {
    text: "I agree to indemnify and hold harmless the Publisher, its officers, employees, and agents from any claims or damages arising out of any breach of the warranties and representations made in this Agreement.",
  },
  {
    text: "This Agreement constitutes the entire understanding between the parties regarding the copyright in the Work and supersedes all prior agreements and understandings, whether written or oral, relating to the same.",
  },
];

export const articleSubmissionTrackingTags = [
  { name: "Acknowledgement", tag: "acknowledgement" },
  { name: "Plagiarisms Check", tag: "plagiarismsCheck" },
  { name: "Editor Assigned", tag: "editorAssigned" },
  { name: "Reviewer Assigned", tag: "reviewerAssigned" },
  { name: "Approved/Rejected", tag: "approvedRejected" },
  { name: "Payment", tag: "payment" },
  { name: "Published", tag: "published" },
];

export const authGuidelinesArticlePoints = {
  article: [
    {
      text: "Abstract: Limited to 400 words without key words.",
    },
    {
      text: "Page Limit: Limited to 15 pages including abstract, references, tables, figures, and their legends.",
    },
    {
      text: "Tables and figures: The total number of tables and figures for the main text is limited to 8.",
    },
    {
      text: "References guideline: Limited to 30 references. Please use the most important and relevant references where possible.",
    },
    {
      text: "Supplemental information: Supplemental information for less important materials, methods, data (tables and figures) is allowed.",
    },
  ],
  review: [
    {
      text: "Abstract: Limited to 400 words.",
    },
    {
      text: "Page Limit: Limited to 15 pages, including abstract, references, figures and tables.",
    },
    {
      text: "References guideline: Limited to 60 references.",
    },
    {
      text: "Supplemental information: Optional.",
    },
  ],
  method: [
    {
      text: "Abstract: Limited to 350 words.",
    },
    {
      text: "Page Limit: Limited to 8 pages, including abstract, references, figures and tables.",
    },
    {
      text: "References guideline: Limited to 20 references.",
    },
    {
      text: "Supplemental information: Supplemental information for less important materials, methods, data (tables and figures) are allowed.",
    },
  ],
  editorial: [
    {
      text: "Abstract: Not relevant.",
    },
    {
      text: "Page Limit: Limited to 8 pages, including references.",
    },
    {
      text: "References guideline: Limited to 10 references.",
    },
    {
      text: "Supplemental information: Optional.",
    },
  ],
  perspective: [
    {
      text: "Abstract: Not relevant.",
    },
    {
      text: "Page limit: Limited to 10 pages includig abstract, references and figure legends.",
    },
    {
      text: "References guideline: Limited to 20 references.",
    },
    {
      text: "Supplemental information: Optional.",
    },
  ],
  manuscriptContent: [
    {
      text: "Title page",
    },
    {
      text: "Abstract",
    },
    {
      text: "Introduction",
    },
    {
      text: "Materials and methods",
    },
    {
      text: "Results",
    },
    {
      text: "Discussion",
    },
    {
      text: "Conclusions",
    },
    {
      text: "Author Contributions",
    },
    {
      text: "Acknowledgments",
    },
    {
      text: "Conflict of interest",
    },
    {
      text: "Data availability",
    },
    {
      text: "References",
    },
  ],
  manuscriptCoverLetter: [
    {
      text: "The title of the manuscript and whether it is an original submission or a re-submission.",
    },
    {
      text: "A highlight that contains 3 to 5 short sentences to state the core findings of the research described in the paper.",
    },
    {
      text: "All authors agree with the submission.",
    },
    {
      text: "The work has not been published or submitted for publication elsewhere, including in another form or language.",
    },
    {
      text: "Please declare all figures, tables and data that are reproduced from other publications; the authors have to obtain authorization from the copyright holders (usually the publisher) for their use, and should include this authorization form with their submission;",
    },
    {
      text: "Please state if the word counts conform to the required word limits;",
    },
    {
      text: "Statement of Conflict of Interests.",
    },
  ],
  manuscriptOrganizationSubmission: [
    {
      text: "Cover letter;",
    },
    {
      text: "Manuscript;",
    },
    {
      text: "Supplementary materials (if applicable).",
    },
  ],
  manuscriptOrganizationSubRevisions: [
    {
      text: "A rebuttal letter;",
    },
    {
      text: "Marked-up version of the manuscript (Microsoft Word) with no figures;",
    },
    {
      text: "Clean (non-highlighted) version of the manuscript;",
    },
    {
      text: "Figures with a resolution of 300 dpi or above are expected;",
    },
    {
      text: "Supplementary files (Microsoft Word or Excel) are expected.",
    },
  ],
  manuscriptOrganizationRequirements: [
    {
      text: "The addition of all authors into the submission system",
      description:
        "The full names of all authors should be entered into the submission system in the same order as shown in the manuscript. Contact information including institution, address and official e-mail addresses must be provided. Please use organizational email accounts as the official author email addresses where possible, such as abc@mtu.edu.",
    },
    {
      text: "Title Page",
      description:
        "A title page should include a succinct title (less than 300 characters), a very concise running title (which should be less than 50 characters, for example, Plant Immune Mechanisms), the full names of all authors including their given names, the affiliations and location (including city, state, country and zip/post code), the full name, and official email address(es) of the corresponding author(s).",
    },
    {
      text: "Abstract",
      description:
        "A brief abstract up to 400 words should state the background and purpose of the research, methods (or rationale), main results and findings, and brief conclusions of the study. The abstract should not contain abbreviations or references and should not be structured.",
    },
    {
      text: "Introduction",
      description:
        "The Introduction should provide a background on the research topic, and a focused literature review that includes known and controversial findings, challenging issues, and the hypothesis of the research. Finally, briefly summarize the materials and methods as well as the research rationale. Note that the Introduction should not contain results or conclusions.",
    },
    {
      text: "Materials and Methods",
      description:
        'Materials and Methods should be described in sufficient detail to allow the research work to be reliably reproduced in another laboratory, and to leave the readers in no doubt as to how the results were derived. Please also remember to include a section on "Statistical Analysis" where the statistical methods, with enough details including threshold cutoff, are provided to enable an appraisal of the work and an analysis of the data to reproduce the results. When the total word number exceeds the limit, it is suggested to check for redundancy in the Introduction, Results, and Discussion for removal rather than to briefly describe the methods without providing sufficient details. In a circumstance where it is difficult to compress other sections, some detailed methods, especially those that are routinely used in most laboratories, can be placed into the Electronic Supplementary Information section.',
    },
    {
      text: "Results",
      description:
        "Results should be presented in a logical sequence in the text, tables and figures, usually matching the order as described in the Materials and Methods. Repeated presentation of the same data in different forms should be avoided. The Results should not include a lengthy discussion.",
    },
    {
      text: "Discussion",
      description:
        "The results should not be restated in the Discussion, but can be recapitulated to support or rebut existing hypotheses, conceived assumptions, or true facts stated in the Introduction or literature. The Discussion should compare and relate new or major findings in the Results to the existing body of knowledge in the field, in terms of improvement or further advance of current knowledge and technologies (Methods paper), and overall significance and contribution to the field as well as the future research focuses that stemmed from this study.",
    },
    {
      text: "Conclusions",
      description:
        "A short conclusion of the study may be presented in a short Conclusions section, or at the end of the Discussion section.",
    },
    {
      text: "Electronic Supplementary Information",
      description: "",
      olType: "a",
      listType: "ol",
      points: [
        {
          text: " All lesser significant figures, tables, and procedures that support the main body of key results and conclusions in the text should be included as Electronic Supplementary Information and uploaded as a separate file (PDF) at the time of manuscript submission.",
        },
        {
          text: 'The availability of Electronic Supplementary Information should be mentioned in a separate paragraph in the manuscript, placed immediately before the References, as: "Electronic Supplementary Information".',
        },
      ],
    },
    {
      text: "Author Contributions",
      description:
        "IFR Discovery requires the independent contributions of each author be provided during online submission and every author must assume public accountability for the content of the submitted work under review. If there are individuals whose contributions do not meet the criteria for authorship, make sure to include appropriate acknowledgments within the manuscript.",
    },
    {
      text: "Acknowledgments",
      description:
        "Acknowledgments should include the funding agency and grant number which provided other resources. Individuals who have contributed to make the research possible, but not sufficiently quantified to be authors should also be included in this section.",
    },
    {
      text: "Conflict of Interest",
      description:
        'A statement must be included for all contributing authors who are involved in various kinds of conflicts. Examples of potential conflicts of interest include employment, consultancies, stock ownership, honoraria, paid expert testimony, patent applications/registrations, and grants or other resources which may potentially influence the decision derived from this research. If no conflict of interest is declared, the following statement should be declared in the manuscript: "The authors declare that they have no conflict of interest".',
    },
    {
      text: "Citations in Text",
      description: "Examples:",
      olType: "1",
      points: [
        {
          text: "Finally, forests play a leading role in the global cycling of energy, carbon, water and nutrients [4-7].",
        },
        {
          text: "For the past millennia, the livelihood of humans has largely depended on forest resources, but these resources are not inexhaustible [2,3].",
        },
      ],
    },
    {
      text: "References",
      description:
        "Please ensure that every reference cited in the text is also present in the reference list. Authors should ensure the accuracy of references, and are encouraged to use EndNote or other reference management tools when writing the manuscript. Only one citation should appear within each reference number. If more than one reference is cited in one place, please separate the numbers by commas with a space.***List numbered references in the Literature Cited with numerals and periods, without parentheses.***Include the following information (in the following order):",
      listType: "ul",
      points: [
        {
          text: "Name(s) of author(s), last name first, followed by initials without periods. Include both (or all)",
        },
        {
          text: 'Initials for each author whenever they were included in the original article or book. Do not leave spaces between initials. Do not use a comma between surnames and initials—use commas only to separate different authors\' names. If a given reference has six or more authors, list the first five, then type "et al." in the bibliography. If a reference has five or fewer authors, list them all.',
        },
        {
          text: "Year of publication of the article or book, followed by a period, with no parentheses. If the article has recently been accepted for publication and is in press, list it in the Literature Cited section. Provide the journal title and expected year of publication, plus volume and pages where known.",
        },
        {
          text: "Title of article or chapter (see above for the policy of individual Annual Reviews journals regarding whether to include titles of articles or chapters).",
        },
        {
          text: "Title of journal (full spelling) or book (not abbreviated unless part of a periodical series).",
        },
        {
          text: "For a book reference, name(s) of editor(s).",
        },
        {
          text: 'Volume number, then a colon and inclusive page numbers; if there is no volume number, inclusive page numbers preceded by a comma and "pp." Do not repeat hundred digits unless required, e.g., 3-10, 71-77, 100-9, 331-35, 1002-3, 1198-202, 1536-38. The issue number can be included in parentheses immediately following the volume if necessary, e.g., 10(4):123-30.',
        },
        {
          text: "For a book reference, place of publication, name of publisher, and edition, should be included if necessary.",
        },
      ],
      egText: "Examples:",
      examples: [
        {
          text: "Journals",
          description: "Author. Year. Title. Journal Volume|:Pages",
          points: [
            {
              text: "West GB, Brown JH, Enquist BJ. 2001. A general model for ontogenetic growth. Nature 413:628-63",
            },
            {
              text: "Zeng Y, Ye S, Yu W, Wu S, Hou W, et al. 2014. Genetic linkage map construction and QTL identification of juvenile growth traits in Torreya grandis. BMC Genetics 15:S2",
            },
          ],
        },
        {
          text: "Books:",
          description:
            "Author. Year. Title|. pp Pages|. Place Published|: Publisher|. Number of Pages pp.",
          points: [
            {
              text: "Boddy L, Hiscox J, Gilmartin EC et al. 2017. Wood decay communities in angiosperm wood. In The Fungal Community: Its Organization and Role in the Ecosystem, Fourth Edition, eds. Dighton J, White JF, 32:619. Boca Raton: CRC Press. pp. 169-89",
            },
          ],
        },
        {
          text: "Book Section:",
          description:
            "Author. Year. Title. In Book Title|, ed. Editor|, Volume|:Pages|. Place Published|: Publisher|. Number of Pages pp|.",
          points: [
            {
              text: "Myles S, Liu D, 2004. The apple genome sheds light on the evolution of Rosaceae, ed. A Smith, Vol. 15, Atlanta: Maximum Academic Press. pp. 66-78.",
            },
          ],
        },
        {
          text: "Patents:",
          description: "Inventor. Year. Country| Patent No. Patent Number",
          points: [
            {
              text: "Denecker J, Hoeberichts F, Muhlenbock P, Van Breusegem F, Van Der Kelen K. 2013. U.S. Means and methods for the reduction of photorespiration in crops. WO Patent No.2014147249A1.",
            },
          ],
        },
        {
          text: "Theses and Dissertations:",
          description:
            "Author. Year. Title|. Thesis Type|. University|, Place Published|. Number of Pages pp.",
          points: [
            {
              text: "Daniell D, 2005. Alternative oxidase is involved in the pathogenicity, development, and oxygen stress response of Botrytis cinerea, Thesis, University of Washington. U.S. pp.55-78.",
            },
          ],
        },
        {
          text: "Conference Proceedings:",
          description:
            "Author. Year Published. Title|. Proc. Conference Name|, Edition, Conference Location, Year of Conference|, Volume|:Pages|. Place Published|: Publisher",
          points: [
            {
              text: "Mohan Jain S. 2013. Mutation-assisted breeding for improving ornamental plants. Proc. XXII International Eucarpia Symposium, Section Ornamentals, Breeding for Beauty, Thuringia, 2013, 714:85-98. Thuringia: Tuorix Press",
            },
          ],
        },
        {
          text: "Magazine Article:",
          description: "Author. Year. Title. Magazine Volume|:Pages",
          points: [
            {
              text: "Hong Y. 2008. Phospholipas Is Involved in the Hyperosmotic Response in Arabidopsis. Agronomy Magazine 20:14-16",
            },
          ],
        },
        {
          text: "Report:",
          description:
            "Author. Year. Title|. Type|. Rep. Report Number|, Institution|, Place Published",
          points: [
            {
              text: "du Jardin P. 2012. The Science of Plant Biostimulants − a bibliographic analysis. Technical Report. Ad. Hoc. Study on Bio-stimulants Products 30-CE0455515/00-96, University of Liège, Liège, Belgium. https://orbi.uliege.be/bitstream/2268/169257/1/Plant_Biostimulants_final_report_bio_2012_en.pdf",
            },
          ],
        },
        {
          text: "Web page:",
          description: "Author/Organization. Year. Title. URL",
          points: [
            {
              text: "Biostimulant Coalition. 2013. What are the biostimulants? www.biostimulantcoalition.org",
            },
          ],
        },
      ],
    },
    {
      text: "Tables and Figures",
      description:
        "Authors should submit tables and figures with clear content. Tables and figures should be numbered consecutively in Arabic numerals as Table 1, 2, 3 and Figure 1, 2, 3.",
      listType: "ul",
      points: [
        {
          text: "Each figure/table should be supplied as an individual file;",
        },
        {
          text: "Letterings on figures should be in Times New Roman (non-bold), and the font size should be 8 ~10 pt. Any letterings/data in bold should be explained in figure caption.",
        },
        {
          text: "The minimum line width for figures should be 0.4 pt;",
        },
        {
          text: "Composite figures should be labeled as Figure 1a, 1b, 1c, etc. with a, b, and c clearly labeled in each panel.",
        },
        {
          text: "Any color images should be saved in RGB color mode at 300 dpi or higher;",
        },
        {
          text: "Any mono line art should be saved in gray mode at 600 dpi;",
        },
        {
          text: "TIFF, EPS, JPG and PDF files are the preferred format for figures. It is recommended that you generate your figures in JPEG format before converting them to PDFs or uploading individual files. This will reduce the file sizes and enable you to obtain figures close to the requirements;",
        },
        {
          text: "Tables should be in editable format. Please avoid using vertical rules and shading in table cells;",
        },
        {
          text: "Table and figure legends should be placed immediately above or below each, table/figure respectively. All designations in the tables or figures should use the lower case alphabet, in the order of a, b, c.",
        },
      ],
    },
    {
      text: "Supplementary Files",
      description: "",
      listType: "ul",
      points: [
        {
          text: "Supplementary figures/tables should be cited/numbered consecutively in the main text;",
        },
        {
          text: "Each supplementary figure/table should be supplied as an individual file;",
        },
        {
          text: "The content in supplementary figures/tables should be clear enough to read;",
        },
        {
          text: "TIFF, EPS, JPG and PDF are the preferred format for supplementary figures;",
        },
        {
          text: "PDF is the preferred format for supplementary tables, but it is acceptable if large tables are supplied as Microsoft Excel files.",
        },
      ],
    },
    {
      text: "Sharing of Research Data, Software and Protocols",
      description:
        "The journal encourages authors to share data that supports their research publication wherever it is appropriate, by interlinking the data with their published articles. Research data refer to the results of observations or experimentation that validate research findings.",
      listType: "ul",
      examples: [
        {
          text: "Data availability",
          description:
            'A statement on data availability is required for all original articles which informs readers about the accessibility of research data linked to a paper and outlines the terms under which the data can be obtained. The statement should contain details about the location of the data that underpins the outcomes presented in the article. This may involve links to publicly archived datasets analyzed or produced during the research, where relevant. The term "data" refers to the fundamental dataset required to comprehend, reproduce, and expand upon the conclusions put forth in the article. We acknowledge that there are circumstances were making research data openly accessible might not be feasible, especially when it could jeopardize individual privacy. In such cases, the manuscript should still include a data availability statement, along with any prerequisites for accessing the data.',
        },
        {
          text: "Software and Protocols",
          description:
            "To facilitate reproducibility and data reuse, the journal also encourages authors to share their software, code, models, algorithms, protocols, methods and other useful materials related to the project. Genome and single cell sequence data, transcriptome, metabolome and other big data sets must be deposited to a reliable and permanent repository before the paper will be published. Unavailability may result in a retraction of the published paper.",
        },
      ],
    },
    {
      text: " Language Editing",
      description:
        "Authors need to ensure the English language is of sufficient quality to be understood so that editors and reviewers can accurately assess the work presented in the manuscript. Language Editing Services can assist authors to improve and polish the language of the manuscript, but authors need to cover the cost of this themselves.***If you are an author whose native language is not English—or you have any concerns regarding the language quality of your manuscript—we recommend having your manuscript professionally edited by a qualified English-speaking researcher in your field prior to submission.",
      listType: "ul",

      egText: "Production",
      examples: [
        {
          points: [
            {
              text: "Proof Correction",
              description:
                "The corresponding author will receive an e-mail containing a PDF proofing along with a Correction format in MS-Word file in which then can manually mention the mistakes and suggest correction edits. To ensure a fast publication process of manuscripts, proof reading and corrections must be returned within 3 days.",
            },
            {
              text: "Article Processing Charges (APCs)",
              description:
                'IFR Discovery levies an Article Processing Charge (APC) per article. Only accepted papers will be charged. Authors will receive a payment email alongside the "License to Publish" form.',
            },
            {
              text: " License to Publish (LTP)",
              description:
                "Publishing Open Access will mean the paper is freely accessible online immediately upon publication. By signing the License to Publish, authors are permitted to post the final, published PDF of their article on a website, institutional repository, or other free public server, immediately on publication. Open access articles are published under a CC BY-NCND License (Creative Commons Attribution-NonCommercial-NoDerivatives 4.0 International License). This license allows readers to copy, distribute, and transmit the contribution as long as it is attributed back to the author, and they do not alter, transform, or build upon the work, nor use it for commercial purposes. Please read the full license for further details at https://creativecommons.org/licenses/by-nc-nd/4.0/",
            },
            {
              text: "Permission for Photographic/Illustration Use",
              description:
                "When re-using figures from a third-party (i.e., another journal) or those which are copyrighted, the authors must submit the documents of permission provided by the original publisher or the copyright holder. The copyright holder, who could be an individual, corporation, or, most frequently, a publisher, must complete the Permission for Photographic/Illustration Use",
            },
          ],
        },
      ],
    },
  ],
};

export const reviewerGuidelinesPoints = [
  {
    text: "Manuscripts undergo a quality check by the editorial office to ensure completeness and prevent plagiarism using iThenticate. Manuscripts with a similarity index over 15% (excluding references) will be unaccepted.",
  },
  {
    text: "The Editor-in-Chief assigns each manuscript to an Associate Editor based on expertise and workload considerations.",
  },
  {
    text: "The handling editor selects at least two reviewers based on expertise, publication history, and past reviews, and invites them to provide feedback on the manuscript.",
  },
  {
    text: "Reviewers have a 2-week deadline to submit their feedback. After receiving review reports, the Associate Editor makes a recommendation to the Editor-in-Chief, who then makes a decision. Recommendations can be: (a) Acceptance, (b) Acceptance with minor revisions, (c) Major revisions required, (d) Rejection with encouragement to revise and resubmit, or (e) Rejection without the possibility of resubmission.",
  },
  {
    text: "The Editor-in-Chief makes the final decision, and the editorial office sends the decision letter to the corresponding authors and all co-authors.",
  },
  {
    text: "Final Decision",
    nestedPoints: [
      {
        text: "Major Revision: The revised manuscript and rebuttal letter are re-reviewed by the original reviewers. Multiple rounds of review may occur if necessary.",
      },
      {
        text: "Minor Revision: Assessed by the Associate Editor without additional review.",
      },
      {
        text: "Rejection with Encouragement to Revise and Resubmit: May or may not be reviewed by the same reviewers or Associate Editor.",
      },
      {
        text: "Acceptance: Manuscript progresses to copy editing, figure editing, typesetting, and proof delivery to corresponding author(s).",
      },
    ],
  },
];

/**
 * Common constants
 */

export const editorTypes = {
  CHIEF_EDITOR: {
    name: "Editor-in-chief",
    tag: "CHIEF_EDITOR",
  },
  ASSOCIATE_EDITOR: {
    name: "Associate Editor",
    tag: "ASSOCIATE_EDITOR",
  },
};

export const editorOptions = [
  {
    name: editorTypes.CHIEF_EDITOR.name,
    value: editorTypes.CHIEF_EDITOR.tag,
  },
  {
    name: editorTypes.ASSOCIATE_EDITOR.name,
    value: editorTypes.ASSOCIATE_EDITOR.tag,
  },
];

export const articlePublishStatus = {
  draft: "DRAFT",
  published: "PUBLISHED",
};
