import React, { useEffect, useState } from "react";
import { Box, Typography, styled } from "@mui/material";
import { ArchiveCard } from "../../../components/website/archivePage";
import { useParams } from "react-router-dom";
import archiveWebsiteServices from "../../../services/archive/website";
import { useDispatch, useSelector } from "react-redux";
import { fetchJournalById } from "../../../redux/slices/webJournal";
import { Loader } from "../../../components";

const ArchiveList = () => {
  const dispatch = useDispatch();
  const { journalId } = useParams();

  const { journalDetails, journalLoading } = useSelector(
    (state) => state.webJournalReducer
  );

  const [archiveList, setArchiveList] = useState({
    data: [],
    loading: false,
  });

  useEffect(() => {
    if (journalId && !journalDetails) {
      setArchiveList((prev) => ({
        ...prev,
        loading: true,
      }));
      dispatch(fetchJournalById({ journalCustomId: journalId }));
    }
  }, [journalId, dispatch]);

  useEffect(() => {
    const fetchArchiveIssues = async () => {
      setArchiveList((prev) => ({
        ...prev,
        loading: true,
      }));
      const resArchiveIssues = await archiveWebsiteServices.getArchiveIssueList(
        { journal: journalDetails?._id }
      );
      if (resArchiveIssues?.data) {
        setArchiveList((prev) => ({
          ...prev,
          data: resArchiveIssues?.data,
          loading: false,
        }));
      } else {
        setArchiveList((prev) => ({
          ...prev,
          loading: false,
        }));
      }
    };

    if (journalDetails?._id) {
      fetchArchiveIssues();
    }
  }, [journalDetails?._id]);

  return (
    <Box
      sx={{
        padding: "20px",
        height: "100%",
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          fontWeight: 600,
          marginBottom: "20px",
        }}
      >
        Archives
      </Typography>
      {journalLoading || archiveList?.loading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
          }}
        >
          <Loader />
        </Box>
      ) : archiveList?.data?.length > 0 ? (
        archiveList?.data?.map((archive, archiveIndex) => (
          <Box sx={{ marginBottom: "30px" }}>
            <ArchiveCard
              key={`${archive.name}_${archiveIndex}`}
              archiveName={archive.name}
              issueList={archive?.issue_data}
            />
          </Box>
        ))
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              wordWrap: "break-word",
            }}
          >
            No Archive Available
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ArchiveList;
