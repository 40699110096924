import React from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { ReactComponent as Professor } from "../../../assets/images/Professor.svg";
import { ReactComponent as Growth } from "../../../assets/images/Growth.svg";
import { ReactComponent as Exams } from "../../../assets/images/Exams.svg";
import { ReactComponent as Fee } from "../../../assets/images/Fee.svg";
import {
  privilegeMembershipAdvantagePoints,
  privilegeMembershipEligibleCriteriaPoints,
} from "../../../helpers/constant";

const DelegateMembership = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        padding: "20px 40px",
        height: "100%",

        // background: theme.palette.primary.litSurface,
      }}
    >
      <Box sx={{ marginBottom: "30px" }}>
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <Grid item sx={12} sm={12} md={4} lg={4}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Professor style={{ maxWidth: "350px" }} />
            </Box>
          </Grid>
          <Grid item sx={12} sm={12} md={8} lg={8}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontWeight: 600,
                marginBottom: "20px",
              }}
            >
              Privileged Membership (PMIFRD)
            </Typography>
            <Typography variant="body1" gutterBottom>
              Begin an enlightening journey toward scholarly excellence with the
              Privileged Membership offered by IFR Discovery. Our membership
              extends beyond traditional publication; it's an opportunity to
              elevate your impact and leave a lasting imprint in the world of
              academic research. Dive into the world of transformative ideas and
              pave your path to influential research articles, establishing your
              legacy within the scholarly community. Join us and let your
              dedication to knowledge propel you forward. With our Privileged
              Membership, you're not just a subscriber; you're a future
              luminary, a catalyst for change, and a visionary in academia.
              Embark on this academic voyage today with our Privileged
              Membership and illuminate the path of your scholarly brilliance.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ marginBottom: "30px" }}>
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <Grid item sx={12} sm={12} md={8} lg={8}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontWeight: 600,
                marginBottom: "20px",
              }}
            >
              Exclusive Advantages
            </Typography>
            <ol>
              {privilegeMembershipAdvantagePoints.map((point) => (
                <li>{point.text}</li>
              ))}
            </ol>
          </Grid>
          <Grid item sx={12} sm={12} md={4} lg={4}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Growth style={{ maxWidth: "350px" }} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ marginBottom: "30px" }}>
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <Grid item sx={12} sm={12} md={4} lg={4}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Exams style={{ maxWidth: "350px" }} />
            </Box>
          </Grid>
          <Grid item sx={12} sm={12} md={8} lg={8}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontWeight: 600,
                marginBottom: "20px",
              }}
            >
              Eligibility Criteria
            </Typography>
            <ol>
              {privilegeMembershipEligibleCriteriaPoints?.map((point) => (
                <li>{point.text}</li>
              ))}
            </ol>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                marginBottom: "20px",
                "& span": {
                  color: theme.palette.danger.main,
                },
              }}
            >
              <em>
                <span>
                  <strong>Note:</strong>
                </span>{" "}
                If you meet any one of the four specified eligibility conditions
                outlined above, you are eligible to Apply for Membership.
              </em>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <Grid item sx={12} sm={12} md={8} lg={8}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontWeight: 600,
                marginBottom: "20px",
              }}
            >
              Fees
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ marginBottom: "20px" }}
            >
              Our Privileged Membership is offered at a nominal fee of 8000INR
              or 125USD.
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ marginBottom: "20px" }}
            >
              Crafted to be an accessible gateway to exclusive benefits, our
              Privileged Membership fee is designed with your convenience in
              mind. We've structured our fees to provide exceptional value,
              ensuring that you can harness a wealth of opportunities without
              bearing a hefty financial burden. With complete transparency, our
              fee structure offers comprehensive advantages that empower you to
              reach your highest potential without stretching your budget. Seize
              this opportunity and pave the way for your future success today.
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ marginBottom: "20px" }}
            >
              To join our ‘Privileged Membership’, visit our 'Apply for
              Membership' page and complete the online form by providing the
              necessary information. Once the form has been submitted, our team
              will review your submitted form and get in touch with your
              communication email address if you are suitable or not for the
              membership and the further process.
            </Typography>
            <Typography variant="body1" gutterBottom>
              If you do not get any response within 24 hours from the time of
              online form submission, you can also notify to our official email
              address{" "}
              <a href={`mailto:${process.env.REACT_APP_IFRD_EMAIL}`}>
                {process.env.REACT_APP_IFRD_EMAIL}
              </a>
            </Typography>
          </Grid>
          <Grid item sx={12} sm={12} md={4} lg={4}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Fee style={{ maxWidth: "350px" }} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default DelegateMembership;
